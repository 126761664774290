<template>
    <div class="w-100 position-relative">
        <div class="card card-primary card-tabs" style="width: 100%;">
            <div class="card-body">
                 <div class="flex-fill d-flex flex-column card-body p-1 pt-0" v-loading="isLoading">                    
                    <VuePerfectScrollbar class="table-responsive p-0 flex-fill">
                        <table v-if="elements.data.length > 0" class="table table-striped table-head-fixed text-nowrap">
                            <thead>
                                <tr>
                                    <th class="pl-2 pt-2 pb-2 text-nowrap">
                                        <div>Machine Id</div>
                                    </th>
                                    <th class="pl-2 pt-2 pb-2" style="width: 1%;">
                                        <div>Customer owned</div>
                                    </th>
                                    <th class="pl-2 pt-2 pb-2 text-left" style="width: 1%;">
                                        <div>Package name</div>
                                    </th>                                    
                                    <th class="pl-2 pt-2 pb-2 text-left" style="width: 1%;">
                                        <div>Boot success</div>
                                    </th>  
                                    <th class="pl-2 pt-2 pb-2 text-left" style="width: 1%;">
                                        <div>Notify at</div>
                                    </th>                                 
                                    <th class="pl-2 pt-2 pb-2 text-center" style="width: 1%;">
                                        <div>Staff</div>
                                    </th>
                                    <th class="pl-3 pt-2 pb-2 text-center" style="width: 1%;">
                                        <div>Created/Updated</div>
                                    </th>
                                    <th class="pl-2 pt-2 pb-2 text-center" style="width: 1%;">
                                        <div>Action</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="element.id" v-for="element in elements.data"  
                                    v-bind:class="{ 'low-balance' : (element.balance < 10) }">
                                    <td class="v-center pl-2 pt-1 pb-1">
                                        <div class="d-flex align-items-center position-relative">
                                            <div class="flex-fill">
                                                <div class="d-flex">
                                                    <div class="d-flex mr-3 position-relative">
                                                        <img :src="`/img/ServiceType_${element.vmMachineInfo.rentalServicePack.serviceType}.svg`"
                                                             style="width: 2.5rem;" />
                                                        <img :src="`/icon/os-${element.vmMachineInfo.operatingSystem}.svg`"
                                                             title="Operating System"
                                                             style="width: 1.5rem; position: absolute; right: 0; bottom: 0.5rem;"/>
                                                    </div>
                                                    <div>
                                                        <div class="mb-0 mt-1">     
                                                            <small class="mr-2">
                                                                <i v-if="element.vmMachineInfo.status === vmMachineStatus.Running" 
                                                                   class="blink_me fa fa-circle text-success"></i>
                                                                <i v-else 
                                                                   class="fa fa-circle text-danger"></i>
                                                            </small>
                                                            <small class="label label-info mr-1 cursor-pointer"
                                                                   @click="viewDetailInfo(element)">
                                                                #{{element.vmMachineId}}
                                                            </small>
                                                            <small class="label label-success mr-1 cursor-pointer" 
                                                                   @click="viewDetailInfo(element)"
                                                                   v-if="element.vmMachineInfo.uptime > 0">
                                                                {{element.vmMachineInfo.uptime | secondDuration}}
                                                            </small>
                                                            <strong class="text-primary cursor-pointer" :title="element.invoiceId" 
                                                                    @click="viewDetailInfo(element)">
                                                                {{element.vmMachineInfo.name}}
                                                            </strong>

                                                            <button type="button" class="btn btn-xs btn-default rounded p-0 pl-1 pr-1 ml-1" 
                                                                    data-toggle="tooltip" title="Click copy to clipboard"
                                                                    @click="copyToClipboard('#infoInputCopy', `ID ${element.vmMachineId} - Name ${element.vmMachineInfo.name}`)"
                                                                    style="font-size: 10px; position: relative; top: -2px;">
                                                                <i class="fa fa-copy text-muted"></i>
                                                            </button>
                                                        </div>
                                                        <div class="mt-0">
                                                            <small style="font-family: 'Arial';" class="mr-2"><strong>{{element.vmMachineInfo.computerName}}</strong></small>

                                                            <small :class="`text-${vmMachineStatusColor[element.vmMachineInfo.status]}`">
                                                                <i>{{element.vmMachineInfo.statusText}}</i>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div :id="`note_${element.id}`" style="right: 0.2rem; top: 0.6rem;"
                                                 class="d-flex align-content-center  align-items-center mr-2 position-absolute">
                                                <div class="alert bg-light cursor-pointer user-note-content p-1 mb-0"
                                                     v-if="element.noteContent" @click="viewDetailInfo(element)">
                                                    <div v-html="element.noteContent"  data-toggle="tooltip" title="Click to view more"
                                                         style="max-width: 15rem;max-height: 2.3rem;overflow: hidden;white-space: normal !important;font-size: 0.8rem;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="v-center text-nowrap pl-2 pt-1 pb-1 text-left">
                                        <div :id="`note_${element.id}`"
                                                class="d-flex align-content-center  align-items-center mr-2">
                                            <div class="cursor-pointer user-note-content p-1 mb-0"
                                                    v-if="element.noteContent" @click="viewDetailInfo(element)">
                                                <div class="d-flex align-items-center">
                                                    <v-avatar size="3rem">
                                                        <v-img id="app-avatar" :src="`/img/avatars/${element.staffSupport}.jpg`" 
                                                                            onerror="this.src='/img/avatar5.png'" />
                                                    </v-avatar>
                                                    <div v-html="element.noteContent" class="alert bg-light  p-1 mb-0 ml-1"  data-toggle="tooltip" title="Click to view more"
                                                            style="max-width: 15rem;max-height: 2.6rem;overflow: hidden;white-space: normal !important;font-size: 0.8rem;">
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="text-muted mr-2">
                                                <button type="button" class="btn btn-default" @click="viewDetailInfo(element)">
                                                    <i class="fa fa-plus-circle"></i> Add note
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="v-center text-nowrap pl-2 pt-1 pb-1">
                                        <div v-if="element.uerInfo !== null">
                                            <div>
                                                <a href="javascript:void(0);" 
                                                   @click="checkAccess('USER_VIEW_DETAIL') ? $getUserDetailInfo(element.userId) : none()">
                                                    <small v-if="element.totaL_RECHARGE_GPUHUB > 0"
                                                           class="mr-1 label bg-gradient-primary">
                                                        Paid
                                                    </small>
                                                    <small v-if="element.totaL_RECHARGE_GPUHUB >= $primeUserMinAmount"
                                                           class="mr-1 label bg-gradient-navy">
                                                        Prime
                                                    </small>
                                                    <strong>
                                                        <span><strong class="text-default">{{element.userInfo.username}}</strong></span>
                                                    </strong>
                                                </a>
                                                <button type="button" class="btn btn-xs btn-default rounded p-0 pl-1 pr-1 ml-1" 
                                                        data-toggle="tooltip" title="Click copy to clipboard"
                                                        @click="copyToClipboard('#infoInputCopy', `${element.userInfo.username}`)"
                                                        style="font-size: 10px; position: relative; top: -2px;">
                                                    <i class="fa fa-copy text-muted"></i>
                                                </button>
                                            </div>                                        
                                            <div>
                                                <a href="javascript:void(0);" 
                                                   @click="checkAccess('USER_VIEW_DETAIL') ? $getUserDetailInfo(element.userId) : none()">
                                                    <small><strong class="text-muted">{{element.userInfo.email}}</strong></small>
                                                </a>
                                                <button type="button" class="btn btn-xs btn-default rounded p-0 pl-1 pr-1 ml-1" 
                                                        data-toggle="tooltip" title="Click copy to clipboard"
                                                        @click="copyToClipboard('#infoInputCopy', `${element.userInfo.email}`)"
                                                        style="font-size: 10px; position: relative; top: -2px;">
                                                    <i class="fa fa-copy text-muted"></i>
                                                </button>
                                            </div> 
                                        </div>
                                    </td>
                                    <td class="text-nowrap v-center pl-2 pt-1 pb-1">
                                        <div :class="`text-${colorGpuNodeColor[element.vmMachineInfo.rentalServicePack.id]}`">
                                            <strong>{{element.vmMachineInfo.packageName}}</strong>
                                        </div>
                                        <div class="text-lowercase">
                                            <strong class="text-muted">
                                                <small>{{element.vmMachineInfo.rentalServicePack.pricing | numFormat('0,0[.][0000]')}}$</small>
                                            </strong>
                                            <small class="text-muted">{{element.vmMachineInfo.rentalServicePack.pricingUnit}}</small>
                                        </div>
                                    </td>
                                    <td class="text-center text-capitalize v-center pl-2 pt-1 pb-1">
                                        <div>{{element.bootSuccess}} {{element.bootSuccess > 1 ? 'times' : 'time'}}</div>
                                    </td>
                                    <td class="text-center text-capitalize v-center pl-2 pt-1 pb-1">
                                        <div><small>{{element.vmMachineInfo.notifiedTimes}}</small></div>
                                        <div><small>{{element.vmMachineInfo.notifiedAt}}</small></div>
                                          
                                    </td>
                                    <td class="text-center v-center pl-2 pt-1 pb-1">
                                        <v-avatar size="3rem" data-toggle="tooltip" :title="element.staffSupport"
                                                  v-if="element.staffSupport">
                                            <v-img id="app-avatar" :src="`/img/avatars/${element.staffSupport}.jpg`" 
                                                             onerror="this.src='/img/avatar5.png'" />
                                        </v-avatar>
                                    </td>
                                    
                                    <td class="text-center text-capitalize v-center pl-2 pt-1 pb-1">
                                        <div><small>Created {{element.createdAt | pretyDateUtc}}</small></div>
                                        <div><small>Updated {{element.vmMachineInfo.lastModifiedAt | pretyDateUtc}}</small></div>
                                    </td>
                                    
                                    <td class="v-center text-nowrap pl-2 pt-1 pb-1">
                                        <div class="action-ctn text-right">
                                            <div>
                                                <button type="button" class="btn btn-block btn-xs btn-secondary mb-2" @click="addToNotifyList(element)">
                                                    Add notify 
                                                </button>

                                            </div>
                                            <div>
                                                <button type="button" class="btn btn-block bg-gradient-danger btn-xs"
                                                        @click="removeImage(element)">
                                                    Remove
                                                </button>
                                            </div>
                                            <div class="mt-2" v-if="warningMail.warningMailList.findIndex(x => x.id === element.id) === -1">
                                                <button type="button" class="btn bg-gradient-warning btn-block btn-xs">
                                                    Add to warning
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-else class="nodata-container flex-fill d-flex align-items-center justify-content-center h-100">
                            <div class="align-self-center text-center">
                                <div class="mb-5"><img src="/static/image/no-job-icon.svg" style="opacity: 0.7;"></div>
                                <h4><strong class="text-muted">There is no machine has been added</strong></h4>
                                <p class="text-muted">All machine will be show here !</p>
                            </div>
                        </div>
                    </VuePerfectScrollbar>
                </div>

            </div>
            <!-- /.card -->
        </div>
    </div>
</template>

<script>
    import commonAct from "@/commonActionHandle.js";
    import lazyLoadComponent from "@/scripts/lazyLoadComponent";
    import SkeletonBox from "@/components/SkeletonBox";
    import { vmMachineStatus } from "@/constant/vmMachineStatusConst";
    import vmMachineApi from "@/api/gpuHub/vmMachines";
    import userApi from "@/api/common/users";
    import baseComponent from "@/scripts/baseComponent";
    export default {
        extends: baseComponent,
        components: {
           
        },
        props: {
            detailMachineItem: {
                type: Object,
                default: null,
            },
        },
        watch: {
            detailMachineItem(newVal) {
                console.log("detailMachineItem", newVal);
            },
        },
        data() {
            return {
                VUE_APP_API_HOST: process.env.VUE_APP_API_HOST,
                vmMachineStatus: vmMachineStatus,
                totalRechargeInfo: {
                    countTrans: 0,
                    totalAmount: 0,
                },
            };
        },
        mounted() {
            
        },
        methods: {
        },
    };
</script>